#home-featured-offers {
    margin-top: 1.5rem;

    .home-featured-offers-container {
        padding-top: 0.7rem;

        hr {
            border: none;
            height: 1px;
            background-color: $cr-pale-grey;
            margin: 0.7rem 0 0 0;
        }

        &:hover {
            background-color: #e4e4e4;
        }
    }
}

@include responsive('tablet') {
    #home-featured-offers {
        .home-featured-offers-container {
            hr {
                margin-right: 2rem;
            }
        }
    }
}
