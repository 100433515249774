.div-container {
  position: relative;
  margin: 1rem 0 0;
  padding: 0;
  background-color: #fff;
  border-radius: 0.75rem;
  color: inherit;

  .tooltip-wrapper {
    cursor: pointer;
  }

  &.black-box {
    border: 4px solid $cr-grey49;
    border-radius: 1.1rem;
  }
  &.with-tabs {
    margin-top: 0;
    border-radius: 0 0.75rem 0.75rem 0.75rem;
  }

  .div-container-title {
    @include flexbox(row, nowrap, space-between);

    border-radius: 0.75rem 0.75rem 0 0;
    color: $cr-grey65;
    margin: 0;
    padding: 1rem 1.25rem;

    & > * {
      align-self: center;
    }

    h2 {
      @include flexbox(row, nowrap, flex-start);

      margin: 0;
      font-size: 1.5rem;
      font-weight: 300;

      & > * {
        align-self: center;
      }

      i,
      svg {
        margin-right: 0.75rem;
      }
      svg {
        width: 24px;
        height: 24px;
      }
      a.info {
        margin: 0 0 0 0.5rem;
      }
      .collapse-icon {
        margin-left: 0.5rem;
      }
    }
  }
  &.black-title {
    .div-container-title {
      background-color: #333;
      color: #fff;

      h2 .info i {
        color: #fff;
      }
    }
    &.gradiant .div-container-title {
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#333+44,02531f+81,000000+82,000000+98 */
      background: #333; /* Old browsers */
      background: -moz-linear-gradient(-45deg, #333 44%, rgba(2, 83, 31, 1) 81.9%, #333 82%, #333 98%); /* FF3.6-15 */
      background: -webkit-linear-gradient(
        -45deg,
        #333 44%,
        rgba(2, 83, 31, 1) 81.9%,
        #333 82%,
        #333 98%
      ); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        135deg,
        #333 44%,
        rgba(2, 83, 31, 1) 81.9%,
        #333 82%,
        #333 98%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#333', endColorstr='#333',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    }
  }
  &.primary-color-title {
    .div-container-title {
      color: #fff;

      h2 .info i,
      i.material-icons {
        color: #fff !important;
      }
    }
  }

  .isOpen {
    .div-container-title {
      margin-bottom: 0.5rem;

      &.collapsable {
        border-bottom: 1px solid #c5c5c5;
      }
    }
  }

  &:not(.isOpen) .div-container-title.collapsable {
    border-radius: 0.75rem;
  }

  .div-container-content {
    position: relative;
    padding-left: 1rem;
    padding-right: 1rem;

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1000;
      padding: 2rem;
      background-color: #{'rgba(255, 255, 255, .85)'};
    }
  }

  .div-container-footer-container {
    margin-top: 46px;

    .div-container-footer {
      border-top: 1px solid #e0e0e0;
      background-color: $pale-grey;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 0 0 10px 10px;
      .link {
        float: right;
        line-height: 45px;
        color: $cr-mid-grey;
        text-transform: uppercase;
        font-weight: bold;

        i {
          position: relative;
          top: 7px;
        }
      }
    }
  }
}

@mixin shorter-fix-6() {
  &.shorter-fix {
    &-first {
      width: 49% !important;
    }
    &-last {
      width: 49% !important;
      margin-right: 0 !important;
    }
  }
}

@include responsive('xlarge') {
  .shorter-xlg-col-9 {
    width: 73%;
    margin-right: 2% !important;
  }

  .shorter-xlg-col-8 {
    width: 64.66667%;
    margin-right: 2% !important;
  }

  .shorter-xlg-col-6 {
    width: 48%;
    margin-right: 2% !important;
    @include shorter-fix-6;
  }

  .shorter-xlg-col-5 {
    width: 40%;
    margin-right: 2% !important;
  }

  .shorter-xlg-col-4 {
    width: 31%;
    margin-right: 2% !important;
  }

  .shorter-xlg-col-3 {
    width: 23%;
    margin-right: 2% !important;
  }
}

@include responsive('large') {
  .shorter-lg-col-9 {
    width: 73%;
    margin-right: 2% !important;
  }

  .shorter-lg-col-8 {
    width: 64.66667%;
    margin-right: 2% !important;
  }

  .shorter-lg-col-6 {
    width: 48%;
    margin-right: 2% !important;
    @include shorter-fix-6;
  }

  .shorter-lg-col-5 {
    width: 40%;
    margin-right: 2% !important;
  }

  .shorter-lg-col-4 {
    width: 31%;
    margin-right: 2% !important;
  }

  .shorter-lg-col-3 {
    width: 23%;
    margin-right: 2% !important;
  }
}

@include responsive('normal') {
  .shorter-md-col-6 {
    width: 48%;
    margin-right: 2% !important;
    @include shorter-fix-6;
  }

  .shorter-md-col-5 {
    width: 40%;
    margin-right: 2% !important;
  }

  .shorter-md-col-4 {
    width: 31%;
    margin-right: 2% !important;
  }
}

@include responsive('tablet') {
  .shorter-sm-col-6 {
    width: 48%;
    margin-right: 2% !important;
    @include shorter-fix-6;
  }
}
