#profile-userinfos {
  padding-top: 40px;
  max-width: 1400px;

  h2 {
    font-size: 1.2em;
  }

  .passwordNotice {
    font-size: 0.9em;
  }

  .select-error {
    margin-top: -5px;
    margin-left: 15px;
    &:first-of-type {
      margin-left: 30px;
    }
  }
}

.user-details-wrapper {
  max-width: 550px;
  margin: auto;
  display: flex;
  flex-flow: column;
  gap: 20px;

  &.user-details-collapseble-wrapper {
    margin: 0;
    max-width: unset;
  }
  .connection-infos,
  .profile-infos {
    position: relative;
    border-radius: 20px;
    border: 1px solid #dadada;
    background: #fff;
    display: flex;
    padding: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;

    @media (max-width: 768px) {
      gap: 10px;
      padding: 15px;
    }

    .info-header {
      width: 100%;
      text-align: left;
      display: flex;
      flex-flow: row;
      align-items: center;
      gap: 16px;
      cursor: pointer;

      .header-wrapper {
        position: absolute;
        width: 100%;
        height: 110px;
        left: 0;

        @media (max-width: 768px) {
          height: 72px;
        }
      }
      h1 {
        color: black;
        margin-bottom: 0;
        margin-right: 8px;
        @media (max-width: 768px) {
          font-size: 18px !important;
        }
      }

      .profile-info-span {
        margin-top: 8px;
        @media (max-width: 768px) {
          display: none;
        }
      }
      .info-btn {
        display: flex;
        align-items: center;
        margin-left: 10px;
        .icon-info {
          font-size: 18px;
          color: white;
          border-radius: 50%;
          background-color: #00bd00;
          @media (min-width: 768px) {
            display: none;
          }
        }
      }

      .icons {
        margin-left: auto;
        display: flex;
        .warning-icon {
          display: none;
          margin-right: 15px;
          &.invalid {
            display: inline-block;
          }
        }

        .arrow-icon {
          &.open {
            rotate: 180deg;
          }
        }
      }
    }

    h2 {
      text-align: left;
    }

    form {
      width: 100%;
      display: none;
      flex-flow: column;
      .profile-info-span-mobile {
        display: none;
        margin: 5px 0 15px 0;
        @media (max-width: 768px) {
          display: flex;
        }
      }

      &.open {
        display: flex;
      }
      .input-field {
        margin: 20px 0;

        input {
          margin: 0;
        }
      }

      .input-field:not(:has(.select-wrapper)) label {
        transform: translateY(-140%);
      }
    }

    button {
      width: 100%;
      @media (max-width: 768px) {
        width: 50%;
      }
    }

    .customSelects {
      display: block;
      .labelBox label {
        font-weight: normal;
      }
      .inputRow button {
        margin-top: 0;
        margin-bottom: 15px;
      }
      .inputRow .dropdown {
        margin: 0;
      }
    }
  }

  .connection-infos {
    label {
      margin-left: -16px;
    }

    .reveal-password {
      border: none;
      margin: 0;
      padding: 0;
      background: #fff;
      all: unset;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: none;
      color: inherit;
      font: inherit;
      text-align: center;
      appearance: none;
      i.icon-open,
      i.icon-close {
        font-size: 17px;
        position: absolute;
        right: 0;
        z-index: 3;
        color: #000 !important;
      }
      &.open {
        i.icon-open {
          display: none;
        }
        i.icon-close {
          display: inline-block;
        }
      }
      &.close {
        i.icon-open {
          display: inline-block;
        }
        i.icon-close {
          display: none;
        }
      }
    }
  }

  .select-error {
    margin-left: 31px;
  }
}

.user-details-wrapper {
  .select-field-error {
    margin-bottom: 10px;
  }
  .select-dropdown {
    margin-bottom: 15px;
  }

  .status {
    @media (max-width: 768px) {
      margin-top: 10px;
    }
  }

  .bottom-row {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: baseline;
      margin-bottom: 0;
    }
    .radio-section {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: -5px;

      label {
        padding-left: 25px;
      }
      .radio-field {
        margin-right: 20px;
      }
    }
  }

  h1 {
    color: black;
  }

  button {
    margin-top: 15px;
    width: 100%;
    z-index: 0;
    @media (max-width: 768px) {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}
