$pending: #f58808;
$approved: #00bd00;
$rejected: #ff0d0d;
$unapproved: #7f7f7f;

$br1: 1279px;

.vastPreRoll {
  padding-top: 100px;
  padding-bottom: 30px;
  max-width: 1920px;

  &.loading {
    display: none;
  }

  .topTitle {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
    margin-top: 30px;

    @media (max-width: $br1) {
      margin-top: 45px;
      gap: 0;
      justify-content: space-between;
    }

    h1 {
      margin: 0;
      white-space: nowrap;
      @media (max-width: $br1) {
        font-size: 25px;
      }

      @media (max-width: 500px) {
        font-size: 5vw;
      }
    }
  }

  .chipTag {
    height: 30px;
    padding: 0 18px;
    border-radius: 6px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    border: 1px solid;
    text-transform: uppercase;
    font-weight: 600;
    cursor: default;
    white-space: nowrap;

    @media (max-width: 500px) {
      font-size: 11px;
      padding: 0 12px;
    }

    &.pending {
      border-color: $pending;
      color: $pending;
    }

    &.approved {
      border-color: $approved;
      color: $approved;
    }

    &.unapproved {
      border-color: $unapproved;
      color: $unapproved;
    }

    &.rejected {
      border-color: $rejected;
      color: $rejected;
    }
  }

  .content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 30px;
    background: #ffffff;
    box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.251);
    margin-top: 25px;
    border: 1px solid rgba(0, 0, 0, 0.16);
    border-radius: 10px;

    @media (max-width: $br1) {
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 20px;
    }

    .leftContent {
      width: 70%;
      font-size: 18px;
      line-height: 27px;

      .subText {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        font-weight: 700;

        .circle {
          width: 9px;
          min-width: 9px;
          height: 9px;
          background: #000000;
          border-radius: 50%;
          margin: 10px 10px 0 0;
        }
      }

      a {
        color: #00bd00;
        text-decoration: underline;
      }

      @media (max-width: $br1) {
        width: 100%;
        line-height: 27px;
      }

      p {
        &:first-child {
          margin: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .btn {
    height: 47px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    margin: 0 0 0 50px;
    padding-left: 22px;
    padding-right: 22px;
    user-select: none;

    @media (max-width: $br1) {
      margin: 45px 0 0;
    }
  }

  .vast-container {
    margin: 20px 0 0;
    .div-container-content {
      padding: 0 80px 30px 80px;

      @media (max-width: $br1) {
        padding: 0 20px 30px 20px;
      }
      .tabs {
        height: unset;
        margin: 25px 0;
        ul {
          li {
            overflow: hidden;
            color: #333;
            text-overflow: ellipsis;
            font-size: 21px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }

      .warning-box {
        display: flex;
        padding: 15px;
        max-height: 55px;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 20px;
        border-radius: 10px;
        background: #dff2df;
        margin-bottom: 40px;

        @media (max-width: $br1) {
          margin: 0 -20px 40px -20px;
          max-height: unset;
          border-radius: unset;
          gap: 10px;
          padding: 10px 30px;
        }
        svg {
          width: 25px;
          height: 25px;
          flex-shrink: 0;
        }
        p {
          color: #1d1d1b;
          font-size: 14px;
          font-weight: 400;

          span {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }

      .preview-content-box {
        display: flex;
        gap: 40px;
        flex-direction: row;
        justify-content: center;

        @media (max-width: $br1) {
          gap: 20px;
          flex-direction: column;
        }

        .preview-info {
          max-width: 352px;

          @media (max-width: $br1) {
            max-width: 100%;
          }
          h2 {
            color: #000;
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin: 0;
          }

          p {
            color: #3d3d3d;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 27px; /* 150% */
            letter-spacing: -0.18px;
            margin: 35px 0;
          }

          .radio-btn-box {
            display: flex;
            flex-direction: column;
            gap: 10px;

            label {
              display: flex;
              align-items: center;
              gap: 10px;
              padding: 10px;
              background: #f8f8f8;
              position: relative;
              cursor: pointer;
              overflow: hidden;
              color: #000;
              font-size: 16px;
              font-weight: 700;
              border-radius: 6px;
              transition: background 0.3s ease-in-out, box-shadow 0.2s ease-in-out;

              &:hover {
                background: #eaeaea;
              }

              /* Custom radio button */
              &::before {
                content: '';
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border: 2px solid #b0b0b0;
                background: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.3s ease-in-out;
              }

              &::after {
                content: '';
                background: #00bd00;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                position: absolute;
                left: 15px;
              }

              input[type='radio'] {
                appearance: none;
                position: absolute;
                width: 0;
                height: 0;
                overflow: hidden;
              }
            }
          }

          .test-btn {
            display: flex;
            padding: 20px 84px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            align-self: stretch;
            background: #00bd00;
            border-radius: 0;
            width: 100%;
            margin-left: 0;
            margin-top: 35px;
          }
        }

        .preview-widget {
          display: flex;
          width: 1018px;
          height: 659px;
          flex-direction: column;
          align-items: flex-start;
          gap: 30px;
          border-radius: 20px;
          background: #ebebeb;

          @media (max-width: $br1) {
            width: 100%;
            height: 620px;
          }

          #creativesAdTools {
            height: 100%;

            .innerRow {
              padding: 0;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
