$imgBorderRadius: 8px;
$grey: #919191;

$br1: 1599px;
$br2: 1365px;
$br3: 1280px;
$br4: 1151px;
$br5: 767px;
$brSwitchLayout: 991px;

@mixin thumnnailImgLarge {
  width: 80px;
  min-width: 80px;
  height: 80px;
}

@mixin thumnnailImgMedium {
  width: 65px;
  min-width: 65px;
  height: 65px;
}

@mixin thumnnailImgSmall {
  width: 50px;
  min-width: 50px;
  height: 50px;
}

@mixin listingNameXLarge {
  font-size: 20px;
}

@mixin listingNameLarge {
  font-size: 18px;
}

@mixin listingNameImgMedium {
  font-size: 16px;
}

@mixin listingNameSmall {
  font-size: 14px;
}

.offerListingDetails {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;

  .thumnnailImg {
    @include thumnnailImgMedium;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: $br2) {
      @include thumnnailImgSmall;
    }

    @media (max-width: $br3) {
      @include thumnnailImgLarge;
    }

    @media (max-width: $br4) {
      @include thumnnailImgMedium;
    }

    @media (max-width: $br5) {
      @include thumnnailImgLarge;
    }

    .preview {
      width: 100%;
      height: 100%;
      background: #000000;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: $imgBorderRadius;

      @media (max-width: $br2) {
        font-size: 11px;
      }

      @media (max-width: $br5) {
        font-size: 12px;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: $imgBorderRadius;
    }
  }

  .listingDetails {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 16px;
    line-height: normal;
    min-width: 0;
    gap: 5px;

    .listingName {
      width: 100%;
      font-weight: 600;
      color: #000000;
      white-space: nowrap;
      overflow: visible;
      text-overflow: ellipsis;
      line-height: 1;

      @include listingNameLarge;

      @media (max-width: $br1) {
        @include listingNameImgMedium;
        overflow: hidden;
      }

      @media (max-width: $br2) {
        @include listingNameSmall;
        overflow: hidden;
      }

      @media (max-width: $br3) {
        @include listingNameImgMedium;
        overflow: hidden;
      }

      @media (max-width: $br4) {
        @include listingNameSmall;
      }

      @media (max-width: $br5) {
        @include listingNameXLarge;
      }
    }
  }
}
