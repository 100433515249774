#home-account-manager {
  .image-container {
    text-align: center;
    margin-top: 1rem;

    .image {
      display: inline-block;
      position: relative;

      .image-tag {
        border-radius: 50%;
        overflow: hidden;
        position: relative;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        img {
          height: 150px;
          width: auto;
          vertical-align: bottom;
        }
      }

      .whale {
        border-radius: 50%;
        position: absolute;
        bottom: 5px;
        right: 5px;
        padding: 6px;
        width: 36px;
        height: 36px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      }
    }
  }

  .infos {
    margin-bottom: 1rem;

    .your-aff {
      border-bottom: 1px solid $cr-pale-grey;
      color: lighten($cr-grey49, 50%);
      font-size: 0.8rem;
      margin-bottom: 0.7rem;
      padding-bottom: 0.7rem;
    }

    .name {
      color: $cr-grey49;
      font-size: 1.1rem;
      font-weight: bold;
      margin-top: 1.1rem;
    }

    .email,
    .skype {
      font-size: 1rem;
      color: $cr-grey49;

      a {
        display: inline-block;
        position: relative;
        color: $cr-grey49;
        padding-left: 20px;

        &:hover {
          color: $cr-green;
        }
      }

      i,
      svg {
        position: absolute;
        top: 0.3rem;
        left: 0px;
        width: 16px;
        font-size: 16px;
        color: $cr-green;
      }
    }

    .get-in-touch {
      opacity: 0.5;
      font-size: 1rem;
      color: $cr-grey49;
    }
  }

  &.dashboard-account-manager {
    .email,
    .skype {
      i,
      svg {
        path {
          fill: $cr-green;
        }
      }
    }
  }

  &.menu-left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .image-container {
      display: flex;
      gap: 12px;
      margin-bottom: 13px;
      .infos-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        text-align: left;
        .your-aff {
          color: $cr-green;
          border-bottom: 0;
          margin-bottom: 0;
        }
        .name {
          text-align: left;
          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    .image-container .image {
      img {
        width: 40px;
        height: 40px;
        vertical-align: bottom;
        border-radius: 50%;
        overflow: hidden;
        position: relative;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      }
      .whale {
        transform: scale(0.75);
        bottom: -5px;
        right: -5px;
      }
    }
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    .infos > div {
      color: #fff;
      &.phone,
      &.skype,
      &.email {
        display: flex;
        align-items: center;
        a,
        label {
          color: #fff;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding: 0 0 0 5px;
        }
        a:hover {
          color: $cr-green;
          background-color: transparent !important;
        }
        label {
          color: #aaa;
        }
        a {
          color: #fff;
        }

        svg {
          width: 20px;
          position: unset !important;
        }
      }
    }

    .infos {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 10px;
    }
  }
}

@include responsive('xlarge') {
  #home-account-manager .infos .email,
  .skype {
    font-size: 1.1rem;
  }
}
