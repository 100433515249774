.skeleton {
  border-radius: 4px;
  animation: skeleton-loading 1.5s infinite;
  background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  border-bottom: 0 !important;

  &.dark {
    animation: skeleton-loading 1.5s infinite;
    background: linear-gradient(90deg, #181818 25%, #242424 50%, #181818 75%);
    background-size: 200% 100%;
  }
}

li:has(> .skeleton) {
  border-bottom: 0 !important;
}

@keyframes skeleton-loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
