$postitialRadius: 20px;
$br1: '650px';
$br2: '767px';
$br3: '1279px';
$br4: '1400px';
$br5: '1600px';

#creativesAdTools {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .introBox {
    width: 100%;
    margin: 0 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    @media (max-width: $br3) {
      flex-direction: column;
      justify-content: center;
      font-size: 14px;
    }

    .icon {
      width: 67px;
      height: 67px;
      border-radius: 7px;
      background: #ccf2cc;
      color: #00bd00;
      display: none;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 20px 0 0;

      @media (max-width: $br3) {
        width: 100%;
        height: 35px;
        flex-direction: row;
        order: 2;
        margin: 20px 0 0;
      }

      svg {
        fill: #00bd00;
        width: 20px;
      }

      .iconText {
        text-align: center;
        font-weight: 600;
        margin: 8px 0 0;
        line-height: 90%;
        @media (max-width: $br3) {
          margin: 0 0 0 8px;
          order: 1;
        }
      }
    }

    .text {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .title {
        width: 100%;
        font-weight: 700;
        font-size: 20px;
        @media (max-width: $br3) {
          font-size: 18px;
        }
        @media (max-width: $br2) {
          font-size: 16px;
        }
      }

      p {
        width: 100%;
        margin: 3px 0 0;

        a {
          color: #00bd00;
        }
      }
    }
  }

  .innerRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 0 0 20px;
    position: relative;

    @media (max-width: $br3) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .loadingCover {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: #000000;
      z-index: 500;
      opacity: 0;
    }

    .loading {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .previewBox {
      flex-grow: 1;
      border-radius: $postitialRadius;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;

      &.chatHead {
        height: 728px;

        @media (max-width: $br3) {
          height: 600px;
        }

        .deviceIcons {
          left: 10px !important;
        }
      }

      @media (max-width: $br3) {
        width: 100%;
        height: 600px;
      }

      .selectTabs {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 25px;
        right: 25px;
        transition: 0.2s;
        z-index: 1000;

        &.chatHead {
          flex-flow: column-reverse;
          gap: 10px;
          right: 10px;
          align-items: flex-end;

          .copyCode {
            background-color: #00bd00;
            border-color: #00bd00;
            padding: 20px;
            @media (max-width: $br2) {
              padding: 10px;
            }
          }

          .copyCode {
            opacity: 1;
            cursor: pointer;
          }

          .showcodeHidden {
            transition: 0.2s;
          }
        }

        @media (max-width: $br4) {
          right: 20px;
          bottom: 20px;
        }

        .tab {
          height: 45px;
          border: 1px solid #333333;
          border-radius: 60px;
          background: #ebebeb;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          font-weight: 500;
          background: #333333;
          color: #ffffff;
          font-weight: 700;
          user-select: none;
          position: relative;
          transition: 0.2s;
          font-size: 17px;

          &.copyCode {
            width: 100%;
            margin: 0 0 10px;
            opacity: 0;
            cursor: default;

            &.active {
              opacity: 1;
              cursor: pointer;
            }

            svg {
              width: 18px;
              margin: 0 6px 0 0;
              fill: #ffffff;
              @media (max-width: $br3) {
                width: 15px;
              }
              @media (max-width: $br2) {
                width: 13px;
              }
            }
          }

          &.showpreviewHidden {
            width: 130px;
            @media (max-width: $br2) {
              width: 110px;
            }
          }

          &.showcodeHidden {
            width: 150px;
            @media (max-width: $br2) {
              width: 130px;
            }
          }

          @media (max-width: $br3) {
            font-size: 14px;
          }

          @media (max-width: $br2) {
            height: 34px;
            font-size: 13px;
          }

          span {
            width: 100%;
            height: 100%;
            transition: 0.2s;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 100;

            &.selected {
              opacity: 0;
              z-index: -1;
            }
          }
        }
      }

      .loadingBox {
        width: 100%;
        height: 100%;
        border-radius: $postitialRadius;
        background: #ebebeb;
        font-family: monospace;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        cursor: default;
      }

      .code-input,
      .iframeBox {
        border-radius: $postitialRadius;
        opacity: 0;
        transition: 0.2s;
        z-index: -1;

        &.active {
          opacity: 1;
          z-index: 10;
        }
      }

      .code-input {
        width: 100%;
        height: 100%;
        position: relative;

        textarea,
        .loadingCodeInput {
          width: 100%;
          height: 100%;
          border-radius: $postitialRadius;
          padding: 10px 20px;
          outline: none;
        }
      }

      .iframeBox {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #ebebeb;
        border-radius: $postitialRadius;

        .deviceIcons {
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: absolute;
          top: 55px;
          left: 25px;
          z-index: 500;
          transition: 0.2s;
          @media (max-width: $br5) {
            top: 27px !important;
          }
          @media (max-width: $br4) {
            top: 20px !important;
            left: 20px;
          }
          @media (max-width: $br1) {
            top: 40px !important;
          }

          &.selected-MOBILE {
            top: 25px;
          }

          .tab {
            width: 45px;
            height: 45px;
            border: 1px solid #333333;
            border-radius: 60px;
            background: #ebebeb;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            @media (max-width: $br1) {
              width: 40px;
              height: 40px;
            }

            svg {
              height: 21px;
              fill: #333333;
              @media (max-width: $br1) {
                height: 17px;
              }
            }

            &:first-child {
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }

            &:last-child {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
              border-left: 0;
            }

            &.selected {
              background: #333333;
              cursor: default;

              svg {
                fill: #ffffff;
              }
            }
          }
        }
      }

      .deviceBox {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        transition: 0.2s;

        &.chatHeadWidth {
          max-width: 850px;
          height: 700px;

          @media (max-width: $br5) {
            max-width: 750px;
            height: 600px;
          }

          @media (max-width: $br3) {
            max-width: 500px;
            height: 349px;
          }

          &.MOBILE {
            width: 425px;

            @media (max-width: 1280px) {
              width: 350px;
            }

            @media (max-width: 450px) {
              width: 100%;
            }
          }
        }

        @media (max-width: $br5) {
          max-width: 500px;
          height: 349px;
        }

        &.MOBILE {
          width: 350px;
          height: 100%;

          @media (max-width: 450px) {
            width: 100%;
          }

          .topBarBox {
            background: none;

            .bar {
              width: 70%;
              border-radius: 0 0 $postitialRadius $postitialRadius;
            }

            .dots {
              opacity: 0;
            }
          }
        }

        .topBarBox {
          width: 100%;
          height: 30px;
          min-height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: 0.2s;
          border: 5px solid #333333;
          background: #333333;
          border-bottom: 0;
          border-radius: $postitialRadius $postitialRadius 0 0;

          .bar {
            width: 100%;
            height: 100%;
            background: #333333;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 6px 20px 0;
            border-radius: $postitialRadius $postitialRadius 0 0;

            .dots {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              transition: 0.2s;

              .dot {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                margin: 0 5px 0 0;

                &.red {
                  background: #e01d1d;
                }

                &.yellow {
                  background: #f4ce00;
                }

                &.green {
                  background: #1de03b;
                }
              }
            }
          }
        }

        .iframeBrowserArea {
          width: 100%;
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
          overflow: hidden;
          border: 5px solid #333333;
          border-top: 0;
          border-radius: 0 0 $postitialRadius $postitialRadius;

          .loadingText {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .inner {
            width: 90%;
            height: 90%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            z-index: 0;
            transition: opacity 0.2s;
            overflow: hidden;

            @media (max-width: $br5) {
              width: 100%;
              height: 100%;
            }

            &.iframe-border {
              @media (min-width: $br5) {
                width: 95%;
                height: 95%;
              }
              iframe {
                background: #757575;
                padding: 10px;
                @media (min-width: $br5) {
                  width: 95%;
                  border-radius: 20px !important;
                  height: 95%;
                }
              }
            }

            &.DESKTOP {
              @media (max-width: $br5) {
                background: #757575;
              }
            }

            &.MOBILE {
              width: calc(100% - 25px);
              height: calc(100% - 25px);

              iframe {
                @media (max-width: $br5) {
                  border-radius: $postitialRadius !important;
                }
              }
            }

            &.switching {
              opacity: 0;
              transition: 0s;
            }
          }

          iframe {
            border: 0;
            padding: 0;
            border-radius: $postitialRadius;
            @media (max-width: $br5) {
              border-radius: 0;
            }
          }

          .iframeCover {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background: #000000;
            opacity: 0;
            z-index: 200;
          }
        }
      }
    }

    .config {
      width: 40%;
      min-width: 450px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 0 0 0 25px;

      &.noDataAvailable {
        opacity: 0.3;
      }

      @media (max-width: $br3) {
        width: 100%;
        min-width: initial;
        padding: 0;
      }

      .titleBox {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        @media (max-width: $br3) {
          order: 2;
          margin: 25px 0 0;
        }

        .title {
          font-size: 26px;
          font-weight: 700;
          margin: 0 8px 0 0;
        }

        #postitialTooltip {
          width: 300px !important;
          text-align: center;
          z-index: 1000;
        }
      }

      .fields {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        @media (max-width: $br3) {
          order: 3;
        }

        .field {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin: 30px 0 0;

          .buttonInputCover {
            height: 44px;

            @media (max-width: $br3) {
              height: 34px;
            }

            .selectInputCover {
              padding-right: 12px !important;
            }
          }

          .selectOuterBox {
            width: 100%;
            max-width: 310px;
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            @media (max-width: $br1) {
              max-width: 280px;
            }

            .input-field,
            .select-wrapper {
              width: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
            }

            .selectInputCover {
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              cursor: pointer;
              user-select: none;
              position: absolute;
              left: 0;
              top: 0;
              z-index: 100;
              padding-right: 25px;

              span {
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }

            input {
              width: 100%;
              opacity: 0;
              z-index: 0;
            }
          }

          .select-wrapper {
            cursor: pointer !important;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;

            > * {
              cursor: pointer !important;
            }

            .dropdown-content {
              li {
                height: auto !important;
              }
            }
          }

          @media (max-width: $br3) {
            margin: 20px 0 0;
          }

          &:last-child {
            margin-bottom: 30px;

            @media (max-width: $br3) {
              margin-bottom: 20px;
            }
          }

          label {
            width: 130px;
            min-width: 130px;
            color: #a8a8a8;
            font-size: 22px;

            @media (max-width: $br3) {
              width: 100px;
              min-width: 100px;
              font-size: 16px;
            }
          }

          .tabsBox,
          input {
            height: 42px;
            @media (max-width: $br3) {
              height: 32px;
            }
          }

          .tabsBox,
          input,
          .percentage,
          .selectInputCover {
            font-size: 18px;
            @media (max-width: $br3) {
              font-size: 14px;
            }
            @media (max-width: $br2) {
              font-size: 13px;
            }
          }

          input,
          .tab,
          .selectInputCover {
            padding: 0 12px;
            @media (max-width: $br2) {
              padding: 0 8px;
            }
          }

          .input-field {
            margin: 0 !important;
          }

          input,
          .selectInputCover {
            border-radius: 10px !important;
            border: 1px solid #333333 !important;
            margin: 0 !important;
            box-shadow: none !important;
            line-height: unset !important;
            color: #000000;
          }

          .caret {
            top: 0;
            bottom: 0;
            right: 5px;
            margin: auto;
          }

          .inputBox {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            input {
              width: 70px;
              margin: 0;
              font-weight: 700 !important;
            }

            .percentage {
              height: 100%;
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              padding: 0 10px 0 0;
              margin: auto;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: default;
            }
          }

          .tabsBox {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border-radius: 10px;

            .tab {
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid #333333;
              border-left: 0;
              cursor: pointer;

              &.selected {
                background: #333333;
                color: #ffffff;
                cursor: default;
              }

              &:first-child {
                border-radius: 10px 0 0 10px;
                border-left: 1px solid #333333;
              }

              &:last-child {
                border-radius: 0 10px 10px 0;
              }
            }
          }

          .positionsBox {
            width: 90px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;

            .positionBox {
              width: 33%;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              margin: 5px 0 0;
              padding: 0 5px 0 0;

              &.centerSquare {
                opacity: 0;
                cursor: default;
              }

              &:nth-child(-n + 3) {
                margin: 0;
              }

              &.selected {
                cursor: default;

                svg {
                  fill: #333333;
                }
              }

              svg {
                width: 100%;
                fill: #e5e5e5;
              }
            }
          }
        }
      }

      .btn {
        width: 100%;
        min-height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: $br3) {
          order: 1;
          margin: 10px 0 0;
        }
      }

      .warning {
        width: 100%;
        background: #fff3cb;
        font-size: 17px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        border-radius: 10px;
        margin: 20px 0 0;
        order: 10;

        @media (max-width: $br3) {
          font-size: 14px;
          margin: 10px 0 0;
        }

        svg {
          margin: 0 20px 0 0;

          @media (max-width: $br2) {
            display: none;
          }
        }

        .textRight {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          color: #c99b05;

          .warningTitle {
            width: 100%;
            font-weight: 700;
          }

          p {
            width: 100%;
            margin: 5px 0 0;
            line-height: 120%;
          }
        }
      }
    }
  }
}
