.achievements {
  #page-wrap {
    background: linear-gradient(180deg, #222 17.13%, #1e1e1e 100%);

    #achievements {
      height: 100%;
      margin-bottom: 150px;

      @media (max-width: 992px) {
        margin-bottom: 100px;
        padding-left: 10px;
        padding-right: 10px;
      }
      .section-container {
        display: flex;
        gap: 28px;
        position: relative;

        @media (max-width: 992px) {
          flex-direction: column;
        }
      }

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0;
        h1 {
          color: #00bd00;
          font-size: 36px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 54px */
          letter-spacing: -0.684px;

          @media (max-width: 992px) {
            font-size: 28px;
          }

          @media (max-width: 424px) {
            font-size: 22px;

            .material-icons {
              font-size: 17px;
            }
          }
        }

        .ranking-box {
          display: flex;
          padding: 6px 30px;
          justify-content: space-between;
          align-items: center;
          border-radius: 20px;
          border: 1px solid #004d00;
          background: linear-gradient(90deg, rgba(10, 59, 38, 0.4) 0%, rgba(1, 38, 46, 0.7) 100%);
          backdrop-filter: blur(4px);
          min-width: 333px;
          min-height: 57px;
          margin: 1.14rem 0 0.912rem 0;
          gap: 70px;

          @media (max-width: 992px) {
            border-radius: 5px;
            min-width: 133px;
            padding: 6px 16px;
            gap: 15px;
          }

          .aff-info-box {
            display: flex;
            gap: 10px;
            align-items: center;

            svg {
              @media (max-width: 992px) {
                height: 32px;
              }
            }
            .info-box {
              display: flex;
              flex-direction: column;
              align-content: center;
              justify-content: flex-start;

              @media (max-width: 992px) {
                display: none;
              }
              .aff-name {
                color: #e4e4e4;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }

              .ranking-text {
                color: #87e764;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }
            }
          }

          .aff-ranking-box {
            display: flex;
            align-items: center;
            gap: 10px;
            .ranking-number {
              color: #87e764;
              font-size: 38px;
              font-style: normal;
              font-weight: 900;
              line-height: normal;

              &.top-20-size {
                font-size: 28px;
                @media (max-width: 992px) {
                  font-size: 26px;
                }

                @media (max-width: 424px) {
                  font-size: 20px;
                }
              }

              @media (max-width: 992px) {
                font-size: 26px;
              }
            }

            .rank-indicator {
              display: flex;
              span {
                display: flex;
                flex-flow: row;
                width: fit-content;

                &.inverse-arrow {
                  transform: rotate(180deg);
                  svg {
                    path {
                      stroke: #ff0d0d;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .selected-badge-container {
        opacity: 0;
        position: relative;
        &.visible {
          animation: showSelectedBadge 0.7s forwards ease-in-out;
        }

        &.hideSelectedBadge {
          animation: hideSelectedBadge 0.3s forwards ease-in-out;
        }

        .content {
          display: grid;
          grid-template-columns: repeat(2, 50%);
          gap: 15px;
          justify-content: center;
        }

        @keyframes showSelectedBadge {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }

        @keyframes hideSelectedBadge {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }

        .selected-badge {
          position: relative;
          align-items: center;
          width: 550px;
          height: fit-content;
          border-radius: 20px;
          border: 1px solid #00bd00;
          background: url(/img/achievements/selected-badge-bg.png) center center no-repeat;
          background-size: cover;
          padding: 35px 42px;

          &:before {
            overflow: hidden;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 50%;
            border-radius: 20px 20px 0 0;
            background: linear-gradient(360deg, rgb(0, 189, 0, 0.1), rgba(0, 189, 0, 0.4) 100%);
            filter: blur(50px);
            z-index: 1; /* Place it behind the content */
          }

          @media (min-width: 992px) {
            display: flex;
            flex-direction: column;
          }

          @media (max-width: 992px) {
            width: unset;
            height: fit-content;
            flex-direction: row;
            justify-content: space-between;
            padding: 20px;
            align-items: flex-start;
            gap: 16px;
          }
          .left-side {
            max-width: 175px;
            display: flex;
            flex-direction: column;
            height: 100%;
            &.special-badge {
              @media (max-width: 992px) {
                .achievements-badge-box {
                  margin-bottom: auto;
                }
              }
            }

            @media (max-width: 992px) {
              width: 90%;
              padding-bottom: 20px;
              justify-content: flex-end;
            }
          }

          .right-side {
            @media (max-width: 992px) {
              display: flex;
              justify-content: center;
              flex-flow: column;
            }
          }

          a {
            width: 100%;
          }

          .achievements-badge-box {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            z-index: 2;

            &.isUnlocked {
              &:before {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 200px;
                height: 200px;
                content: '';
                border-radius: 100%;
                animation: glowAnimation 2s infinite;
                animation-delay: 0.5s;
                z-index: 3;

                @media (max-width: 992px) {
                  width: 75px;
                  height: 75px;
                }

                @keyframes glowAnimation {
                  0% {
                    box-shadow: 0 0 30px 15px var(--glow-color-initial);
                  }
                  50% {
                    box-shadow: 0 0 80px 20px var(--glow-color-50-percent);
                  }
                  100% {
                    box-shadow: 0 0 30px 15px var(--glow-color-initial);
                  }
                }
              }

              &.login-streak-icon {
                &:before {
                  width: 170px;
                  height: 170px;

                  @media (max-width: 992px) {
                    width: 65px;
                    height: 65px;
                  }
                }
              }
            }
            @media (max-width: 992px) {
              width: 100%;
            }

            img {
              height: 337px;
              z-index: 3;
              @media (max-width: 992px) {
                width: 100%;
                height: auto;
                &.bronze {
                  height: 110%;
                }
              }
            }

            .padlock {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              z-index: 10;
              display: flex;
              justify-content: center;
              align-items: center;
              svg {
                scale: 2.5;
                @media (max-width: 992px) {
                  scale: 1.5;
                }
              }
            }

            .level {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              z-index: 10;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #01262e;
              font-size: 90px;
              font-weight: 700;

              &.emerald,
              &.gold {
                font-size: 80px;
              }

              @media (max-width: 992px) {
                font-size: 36px;

                &.emerald,
                &.gold {
                  font-size: 30px;
                }
              }
            }

            @media (max-width: 376px) {
              margin-top: auto;
            }
          }

          .selected-badge-current-level {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            margin: -20px 0 0 0;

            @media (max-width: 992px) {
              justify-content: flex-start;
              margin: 0 0 20px 0;

              &.progressive {
                justify-content: flex-end;
                margin-bottom: 10px;
              }
            }

            p {
              display: inline-flex;
              padding: 4px 20px;
              justify-content: center;
              align-items: center;
              gap: 10px;
              margin: 0;
              border-radius: 10px;
              border: 1px solid #004d00;
              opacity: 0.9;
              background: linear-gradient(90deg, #0a3b26 0%, #01262e 100%);
              backdrop-filter: blur(7.5px);
              @media (max-width: 992px) {
                padding: 4px 14px;
                border-radius: 5px;
              }

              &.with-icon {
                margin-left: -15px;
                font-style: normal;
                font-weight: 500;
                text-transform: unset;
                font-size: 14px;
                width: 100px;

                &.locked {
                  border-radius: 10px;
                  border: 1px solid #707070;
                  opacity: 0.9;
                  background: #1d1d1b;
                  backdrop-filter: blur(7.5px);
                  color: #707070;
                }
              }

              color: #00bd00;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 18.2px; /* 227.5% */

              .lvl-number {
                color: #00bd00;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 18.2px; /* 130% */
              }
            }

            .icon-container {
              border-radius: 50%;
              border: 1px solid #00bd00;
              width: 30px;
              height: 30px;
              background-color: #01262e;
              z-index: 2;
              display: flex;
              align-items: center;
              justify-content: center;

              &.locked {
                border: 1px solid #707070;
                opacity: 0.9;
                background: #1d1d1b;
                backdrop-filter: blur(7.5px);
              }
            }
          }

          .progress-bar-box {
            display: flex;
            flex-flow: row;
            align-items: center;
            margin: 10px 0 0 0;
            width: 100%;

            @media (max-width: 992px) {
              max-width: 175px;
            }

            @media (max-width: 376px) {
              margin-top: auto;
            }

            .left-icon-box,
            .right-icon-box {
              width: 40px;
              height: 40px;
              flex-shrink: 0;
              border-radius: 60px;
              border: 1px solid #00bd00;
              background: #01262e;
              display: flex;
              align-items: center;
              justify-content: center;

              @media (max-width: 992px) {
                width: 20px;
                height: 20px;

                img {
                  max-width: 15px;
                  max-height: 15px;
                }
              }

              .lvl-1 {
                width: 40px;
                height: 13px;

                @media (max-width: 992px) {
                  width: 20px;
                  height: 10px;
                }
              }

              .lvl-2 {
                width: 60px;
                height: 23px;

                @media (max-width: 992px) {
                  width: 40px;
                  height: 13px;
                }
              }

              .lvl-3 {
                width: 70px;
                height: 33px;

                @media (max-width: 992px) {
                  width: 40px;
                  height: 23px;
                }
              }

              .lvl-4 {
                width: 90px;
                height: 43px;

                @media (max-width: 992px) {
                  width: 40px;
                  height: 33px;
                }
              }
            }

            .left-icon-box {
              z-index: 2;
              margin-right: -15px;
            }

            .right-icon-box {
              z-index: 2;
              margin-left: -15px;
            }

            .progress-bar {
              border-radius: 10px;
              border: 0.75px solid #00bd00;
              background: #01262e;
              width: 100%;
              height: 22px;
              display: flex;

              &.with-left-icon {
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                padding-left: 10px;
              }
              &.with-right-icon {
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                padding-right: 10px;
              }

              @media (max-width: 992px) {
                max-width: 175px;
                height: 10px;
              }
              .bar {
                transition: width 0.3s ease-in-out;
                border-radius: 10px;
                background: linear-gradient(90deg, rgba(0, 189, 0, 0.75) 0%, #bdff00 100%);

                &.with-left-icon {
                  border-top-left-radius: 0px;
                  border-bottom-left-radius: 0px;
                }
                &.with-right-icon {
                  border-top-right-radius: 0px;
                  border-bottom-right-radius: 0px;
                }
              }
            }
          }

          .progress-info {
            margin-top: 10px;
            display: flex;
            padding: 4px 20px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 10px;
            border: 1px solid #004d00;
            opacity: 0.9;
            background: linear-gradient(90deg, #0a3b26 0%, #01262e 100%);
            backdrop-filter: blur(7.5px);

            color: #00bd00;
            font-size: 16px;
            font-style: normal;
            font-weight: 800;
            line-height: 18.2px;

            @media (max-width: 992px) {
              padding: 5px 10px;
              gap: 3px;
              color: #00bd00;
              font-size: 10px;
              width: fit-content;
              margin: 10px auto 0 auto;
            }
          }

          .selected-badge-info {
            display: flex;
            padding: 30px 20px;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            border-radius: 10px;
            border: 1px solid #004d00;
            background: linear-gradient(90deg, rgba(10, 59, 38, 0.2) 0%, rgba(1, 38, 46, 0.5) 100%);
            backdrop-filter: blur(4px);
            margin-bottom: 20px;
            margin-top: 30px;

            @media (max-width: 992px) {
              margin-top: 7px;
              gap: 10px;
              height: fit-content;
              padding: 20px;
            }

            h2 {
              color: #00bd00;
              text-align: center;
              font-size: 28px;
              font-style: normal;
              font-weight: 700;
              line-height: 18.2px; /* 65% */
              text-transform: capitalize;
              margin: 0;

              @media (max-width: 992px) {
                font-size: 20px;
              }
            }

            p {
              color: #00bd00;
              text-align: center;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 18.2px; /* 130% */
              margin: 0;

              a {
                color: #00bd00;
                text-decoration: underline;
              }

              @media (max-width: 992px) {
                font-size: 12px;
              }
            }
          }

          .owned-by-section {
            border-radius: 10px;
            background: rgba(1, 24, 0, 0.75);

            @media (max-width: 992px) {
              width: 100%;
              max-width: unset;
            }

            .owned-by-icon-box {
              display: flex;
              width: 32px;
              height: 32px;
              padding: 8.5px 8px 7.5px 8px;
              justify-content: center;
              align-items: center;
              border-radius: 50px;
              border: 1px solid #00bd00;
              background: #01262e;
              box-shadow: 0 0 20px -2px #00bd00;

              &.locked {
                border: 1px solid #707070;
                box-shadow: 0 0 20px -2px #707070;
              }

              @media (max-width: 992px) {
                width: 20px;
                height: 20px;
                padding: 4px;
              }

              svg {
                @media (max-width: 992px) {
                  height: unset;
                }
              }
            }
          }

          .glowing-border {
            position: absolute;
            bottom: -10px;
            left: 50%;
            transform: translateX(-50%);
            height: 20px;
            background: linear-gradient(to right, limegreen, yellowgreen);
            border-radius: 50px;
            box-shadow: 0 0 15px limegreen, 0 0 30px limegreen, 0 0 45px yellowgreen;
            width: 65%;
            z-index: -1;
          }
        }

        button {
          &.cta {
            width: 100%;
            margin: 40px 0;
            padding: 15px 0;
            @media (max-width: 992px) {
              margin: 20px 0;
            }
          }
        }
      }

      h2 {
        color: #00bd00;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 30px */
        letter-spacing: -0.38px;
        margin: 0;
      }

      .owned-by-section {
        display: flex;
        align-items: center;
        padding: 7px 20px;
        gap: 10px;
        max-width: 289px;
        justify-content: center;

        &.highlight {
          border-radius: 10px;
          padding: 8.5px 8px 7.5px 8px;
          justify-content: center;
          align-items: center;
          border-radius: 50px;
          border: 1px solid #00bd00;
          background: #01262e;

          @media (max-width: 992px) {
            padding: 2px 6px;
            margin-top: auto;
            gap: 1px;
          }
        }

        .owned-by-icon-box {
          display: flex;
          &.highlight {
            display: flex;
            width: 32px;
            height: 32px;
            padding: 8.5px 8px 7.5px 8px;
            justify-content: center;
            align-items: center;
            border-radius: 50px;
            border: 1px solid #00bd00;
            background: #01262e;
          }

          svg {
            @media (max-width: 992px) {
              height: 12px;
            }
          }
        }

        @media (max-width: 992px) {
          display: flex;
          padding: 8px 10px;
          align-items: center;
          gap: 10px;
          justify-content: center;
        }

        p {
          color: #00bd00;
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: 18.2px; /* 130% */
          margin: 0;

          &.locked {
            color: #707070;
          }

          @media (max-width: 992px) {
            font-size: 9px;
            line-height: 14px; /* 116.667% */
            text-align: left;
          }
        }
      }

      .achievements-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
        position: absolute;
        left: 0;
        z-index: 2;
        transform: translateY(0);
        width: 100%;
        overflow: hidden;
        padding-bottom: 80px;
        @media (max-width: 992px) {
          display: flex;
          width: 100%;
          min-width: 125px;
          padding: 22px 10px 40px 10px;
          flex-direction: column;
          align-items: center;
          gap: 20px;
          border-radius: 20px;
          border: 1px solid #004d00;
          background: linear-gradient(90deg, rgba(10, 59, 38, 0.2) 0%, rgba(1, 38, 46, 0.5));
          backdrop-filter: blur(4px);
        }

        @media (max-width: 376px) {
          padding: 22px 0 40px 0;
        }

        .achievement-slider-title {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .achievement-slider-arrows {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .prev {
              margin: 0 10px 0 0;
            }

            .prev,
            .next {
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;

              img,
              svg {
                width: 20px;
                height: 20px;

                @media (max-width: $maxw1) {
                  width: 20px;
                  height: 20px;
                }
              }
            }
          }
        }

        &.slide-right {
          animation: slideRight 0.7s ease-in-out forwards;

          @media (max-width: 992px) {
            animation: unset;
          }
        }

        &.slide-left {
          animation: slideLeft 0.7s ease-in-out forwards;

          @media (max-width: 992px) {
            animation: unset;
          }
        }

        @keyframes slideLeft {
          0% {
            transform: translateX(578px);
          }
          80% {
            transform: translateX(-20px);
          }
          95% {
            transform: translateX(0px);
          }
          100% {
            transform: translateX(0px);
          }
        }

        @keyframes slideRight {
          0% {
            transform: translateX(0px);
          }
          80% {
            transform: translateX(598px);
          }
          95% {
            transform: translateX(578px);
          }
          100% {
            transform: translateX(578px);
          }
        }

        .swiper {
          height: 100%;
          overflow: visible;
          margin: 0;
          user-select: none;
          .swiper-wrapper {
            .swiper-slide {
              padding: 0;
              flex: 0 0 auto;
              height: 300px;
              width: 250px;

              @media (max-width: 992px) {
                height: 150px;
                width: 77px;
              }
            }
            .slide {
              height: 300px;
              width: 250px;

              @media (max-width: 992px) {
                height: 150px;
                width: 77px;
              }
            }
          }
        }

        .streak-section,
        .other-badges-section {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          align-content: flex-start;
          gap: 20px;
          flex-wrap: wrap;
        }

        .streak-section,
        .special-section {
          display: flex;
          flex-direction: column;
          width: 100%;
          @media (max-width: 992px) {
            width: 100%;
            overflow: visible;
          }
          .badge-list {
            overflow: visible; // Avoid clipping
            width: 100%;
            gap: 20px;
            display: flex;
            flex-flow: column;

            @media (max-width: 992px) {
              flex-flow: row wrap;
              gap: 15px 10px;
              display: flex;
            }

            @media (max-width: 540px) {
              display: grid;
              grid-template-columns: repeat(4, 77px);
              gap: 15px 5px;
              padding: 0px 4px 1px 4px;
              align-items: center;
              align-content: center;
              justify-content: space-between;

              @media (max-width: 387px) {
                display: grid;
                grid-template-columns: repeat(3, 77px);
                justify-content: space-between;
              }

              @media (max-width: 376px) {
                gap: 15px 5px;
              }
            }

            .subtitle {
              justify-content: space-between;

              @media (max-width: 992px) {
                h2 {
                  margin: 0;
                }

                @media (max-width: 540px) {
                  margin-bottom: 0;
                  grid-column: span 4;
                  width: 100%;
                }
              }
            }

            .slide {
              @media (max-width: 992px) {
                width: 77px;
              }
            }

            .current-gamification-bagde-wrapper {
              position: relative;
              height: 300px;

              @media (max-width: 992px) {
                height: 150px;
              }

              @media (max-width: 387px) {
                margin-bottom: 15px;
              }

              .glowing-border,
              .hover-glowing-border {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                height: 20px;
                background: linear-gradient(to right, limegreen, yellowgreen);
                border-radius: 50px;
                box-shadow: 0 0 15px limegreen, 0 0 30px limegreen, 0 0 45px yellowgreen;
                z-index: -1;
              }

              .glowing-border {
                animation: glowingBorderSelection 0.5s ease-in-out forwards;
              }

              .hover-glowing-border {
                width: 65%;
                bottom: -5px;
              }

              @keyframes glowingBorderSelection {
                0% {
                  width: 65%;
                  bottom: -5px;
                }
                50% {
                  bottom: -10px;
                  width: 95%;
                }
                100% {
                  width: 65%;
                  bottom: -5px;
                }
              }
            }

            .current-gamification-bagde {
              cursor: pointer;
              height: 300px;
              display: flex;
              width: 250px;
              min-width: 250px;
              padding: 15px 10px;
              flex-direction: column;
              align-items: center;
              border-radius: 20px;
              border: 2px solid #004d00;
              background: linear-gradient(90deg, rgba(10, 59, 38, 0.2) 0%, rgba(1, 38, 46, 0.5) 100%);
              backdrop-filter: blur(4px);
              position: relative;
              z-index: 1;

              &.upcoming-badge {
                border: 1px solid #707070;
                cursor: unset;

                @media (max-width: 992px) {
                  border: 0;
                }

                &:hover {
                  border: 1px solid #707070;
                  box-shadow: unset;

                  @media (max-width: 992px) {
                    box-shadow: unset;
                    border: 0;
                  }
                }

                .badge-box {
                  border: 1px solid #707070;
                  .badge {
                    max-height: 104px;
                    width: auto;
                    @media (max-width: 992px) {
                      max-height: 55px;
                    }
                  }
                }

                .badge-name {
                  color: #707070;
                }

                .progress-bar-box {
                  .progress-bar {
                    border: 1px solid #707070;
                    background: linear-gradient(
                      90deg,
                      rgba(29, 29, 27, 0) 0%,
                      #1d1d1b 25%,
                      #1d1d1b 75%,
                      rgba(29, 29, 27, 0) 100%
                    );
                  }
                }

                .owned-by-section {
                  @media (max-width: 992px) {
                    border: 1px solid #676968;
                    background: #1d1e1c;
                  }
                  p {
                    color: #707070;
                  }
                }
              }

              @media (max-width: 992px) {
                padding: 0 5px;
                height: 150px;
                min-height: unset;
                min-width: unset;
                border: 0;
                background: unset;
                gap: 0;
                width: 77px;
              }
              &:hover {
                border: 2px solid #00bd00;
                box-shadow: 0px 0px 16px 0px #00bd00 inset, 0px 0px 8px 0px #00bd00;

                @media (max-width: 992px) {
                  box-shadow: unset;
                  border: 0;
                }
              }

              &.selected {
                @media (min-width: 992px) {
                  animation: borderSelection 0.5s ease-in-out forwards;
                }

                @media (max-width: 992px) {
                  border: 0;
                }

                .badge-box {
                  animation: borderSelection 0.5s ease-in-out forwards;
                }
              }

              @keyframes badgeScalingSelection {
                0% {
                  scale: 1;
                }

                50% {
                  scale: 1.2;
                }

                100% {
                  scale: 1;
                }
              }

              @keyframes borderSelection {
                0% {
                  border: 2px solid #00bd00;
                }

                50% {
                  border: 4px solid #00bd00;
                }

                100% {
                  border: 1px solid #00bd00;
                }
              }

              .selected-icon-mobile {
                position: absolute;
                top: 5px;
                right: 5px;
                z-index: 1;
              }

              .badge-box {
                height: 145px;
                width: 175px;
                border-radius: 20px;
                border: 1px solid #00bd00;
                background: linear-gradient(90deg, rgba(10, 59, 38, 0.2) 0%, rgba(1, 38, 46, 0.5) 100%);
                backdrop-filter: blur(4px);
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;

                @media (max-width: 992px) {
                  height: 75px;
                  width: 75px;
                  padding: 5px;
                  border-radius: 10px;
                  display: flex;
                  justify-content: center;
                  margin-bottom: 0;
                  align-items: center;
                }

                .padlock {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  z-index: 2;
                }

                .level {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  z-index: 2;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  color: #01262e;
                  font-size: 45px;
                  font-weight: 700;

                  &.emerald,
                  &.gold {
                    font-size: 35px;
                  }

                  @media (max-width: 992px) {
                    font-size: 18px;

                    &.emerald,
                    &.gold {
                      font-size: 14px;
                    }
                  }
                }

                .badge-bg {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  z-index: -1;

                  @media (max-width: 992px) {
                    display: none;
                  }
                }
                .badge {
                  max-height: 145px;
                  width: auto;
                  @media (max-width: 992px) {
                    max-height: 55px;
                  }
                }
              }

              .badge-name {
                color: #00bd00;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 18.2px; /* 101.111% */
                text-transform: capitalize;
                text-align: center;
                width: 100%;
                margin-bottom: 12px;

                &.progressive {
                  margin-top: -5px;

                  @media (max-width: 992px) {
                    margin-top: 12px;
                  }
                }

                @media (max-width: 992px) {
                  text-align: center;
                  font-size: 10px;
                  height: 20px;
                  line-height: 14px;
                  margin-top: 12px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-bottom: 0;
                }
              }

              .progress-bar-box {
                display: flex;
                flex-flow: row;
                align-items: center;
                position: relative;
                margin-bottom: 12px;

                @media (max-width: 992px) {
                  width: 100%;
                  margin-bottom: 0;
                }

                .left-icon-box,
                .right-icon-box {
                  width: 20px;
                  height: 20px;
                  flex-shrink: 0;
                  border-radius: 60px;
                  border: 1px solid #00bd00;
                  background: #01262e;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  @media (max-width: 992px) {
                    width: 12px;
                    height: 12px;

                    img {
                      max-width: 11px;
                      max-height: 11px;
                    }
                  }

                  img {
                    max-width: 16px;
                    max-height: 16px;
                  }

                  .lvl-1 {
                    width: 20px;
                    height: 15px;

                    @media (max-width: 992px) {
                      width: 10px;
                      height: 10px;
                    }
                  }

                  .lvl-2 {
                    width: 30px;
                    height: 23px;

                    @media (max-width: 992px) {
                      width: 20px;
                      height: 13px;
                    }
                  }

                  .lvl-3 {
                    width: 50px;
                    height: 23px;

                    @media (max-width: 992px) {
                      width: 30px;
                      height: 23px;
                    }
                  }

                  .lvl-4 {
                    width: 70px;
                    height: 33px;

                    @media (max-width: 992px) {
                      width: 30px;
                      height: 33px;
                    }
                  }
                }

                .left-icon-box {
                  z-index: 2;
                  margin-right: -15px;
                  @media (max-width: 992px) {
                    margin-right: -5px;
                  }
                }

                .right-icon-box {
                  z-index: 2;
                  margin-left: -15px;
                  @media (max-width: 992px) {
                    margin-left: -5px;
                  }
                }

                .progress-bar {
                  border-radius: 10px;
                  border: 0.75px solid #00bd00;
                  background: #01262e;
                  max-width: 175px;
                  height: 10px;
                  display: flex;
                  width: 175px;

                  &.with-left-icon {
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                    padding-left: 10px;
                    @media (max-width: 992px) {
                      padding-left: 4px;
                    }
                  }
                  &.with-right-icon {
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                    padding-right: 10px;

                    @media (max-width: 992px) {
                      padding-right: 4px;
                    }
                  }

                  @media (max-width: 992px) {
                    width: 74px;
                    height: 4px;
                    margin: 8px 0;
                  }

                  .bar {
                    transition: width 0.3s ease-in-out;
                    border-radius: 10px;
                    background: linear-gradient(90deg, rgba(0, 189, 0, 0.75) 0%, #bdff00 100%);

                    &.with-left-icon {
                      border-top-left-radius: 0px;
                      border-bottom-left-radius: 0px;
                    }
                    &.with-right-icon {
                      border-top-right-radius: 0px;
                      border-bottom-right-radius: 0px;
                    }
                  }
                }
              }

              .special-card-footer {
                position: absolute;
                bottom: 0;
                height: 20px;
              }
            }
          }
        }
      }
    }
  }
}
